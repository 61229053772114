import React, { useState } from 'react';

const Activity_endpoint = ({ title, onButtonClick }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleClick = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div
            className={`m-auto flex gap-1`}
            onClick={() => handleClick()} 
            style={{ transition: 'border 300ms' }}
        >
            <div 
                className={` rounded-[5px] flex items-center justify-center px-2 gap-[8px] ease-in-out duration-300
                ${isChecked ? 'border-[#1d428a] border-2' : 'border-transparent'}
                ${isChecked ? 'bg-white text-main-blue' : 'bg-main-blue text-white'}`}
            >
                <div className={`text-p font-semibold w-[120px] font-quicksand ease-in-out duration-300 ${isChecked ? 'text-main-blue' : 'text-white'}`}>
                    {title}
                </div>
                <button onClick={onButtonClick} >
                    {isChecked ? (
                        <svg className='ease-in-out duration-300' width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27 19H8V16H27V19Z" fill="#1d428a"/>
                            <path d="M16 26L16 9H19L19 26H16Z" fill="#1d428a"/>
                        </svg>
                    ) : (
                        <svg className='ease-in-out duration-300' width="37" height="34" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.5 18H8.5V16H27.5V18Z" fill="white"/>
                        </svg>
                    )}
                </button>
            </div>
        </div>
    );
};

export default Activity_endpoint;