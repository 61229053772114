import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchLocations,
  addNewLocation,
  updateLocation,
  deleteLocation,
  fetchLocationById,
} from "./api"; // Import des fonctions API

// Hook pour récupérer les locations
export const useLocationsQuery = () => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
  });
};

// Hook pour récupérer une location par slug
export const useLocationQuery = (slug) => {
  return useQuery({
    queryKey: ["location", slug],
    queryFn: () => fetchLocationById(slug),
    enabled: !!slug, // Permet d'éviter d'exécuter la requête si l'id est null ou undefined
  });
};

// Hook pour ajouter une nouvelle location
export const useAddLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addNewLocation, {
    onSuccess: () => {
      // Invalidate and refetch locations after a successful mutation
      queryClient.invalidateQueries(["locations"]);
    },
  });
};

// Hook pour mettre à jour une location
export const useUpdateLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(({ id, updatedData }) => updateLocation(id, updatedData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
    },
  });
};

// Hook pour supprimer une location
export const useDeleteLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteLocation(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
    },
  });
};
