import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Bouton from "./Bouton";
import NavbarLink from "./navbar/NavbarLink";
import Logo from "./navbar/Logo";

export default function NavBar() {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-white px-0 mx-0 shadow-md fixed z-50 w-full">
      <div className=" mx-auto w-[90%] flex  flex-row justify-between py-3">
        <Logo />
        <div className="hidden md:flex gap-5 items-center text-[#333333]">
          <NavbarLink />
        </div>
        <div className="flex items-center gap-4 ">
          <NavLink
            to="/salle"
            className=" items-center hidden md:flex py-1 px-4 text-sm font-medium gap-2 bg-main-blue border-2 border-[#1D428A] text-white rounded-full" // Augmenté légèrement la largeur
          >
            <img src="../img/mouse-pointer.png" className="w-[20px]" />
            <span className="text-md">Recherche</span>
          </NavLink>
          {open ? (
            <FontAwesomeIcon
              onClick={() => setOpen(!open)}
              icon={faTimes}
              className="curseur-pointer md:hidden block text-xl"
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => setOpen(!open)}
              icon={faBars}
              className="curseur-pointer md:hidden block text-xl"
            />
          )}
          <Link to="/partenaires/devenir-partenaire">
            <Bouton
              label="Prenez rendez-vous"
              className=" bg-main-red hidden md:block text-white  font-semibold rounded-full py-2 px-3 text-sm " // Augmenté la largeur et diminué l'espacement
            />
          </Link>
          {/* navmobile */}
          <div
            className={`
        md:hidden bg-white fixed w-full  text-2xl h-full top-0 overflow-y-auto bottom-0 pt-2 shadow-sm opacity- pl-
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
          >
            <div className="flex justify-between items-center px-5 py-2 pt">
              <div className="logo w-[100px] flex items-center">
                <img className="" src="../logo1.png" alt="Logo de ketket" />
              </div>
              <FontAwesomeIcon
                onClick={() => setOpen(!open)}
                icon={faTimes}
                className="curseur-pointer md:hidden block text-3xl"
              />
            </div>

            <div className="flex flex-col items-start py-6 gap-5  justify-start">
              <div className="flex flex-col  w-full ">
                <NavLink
                  className={` uppercase  text-sm hover:bg-blue-50 hover:font-bold border-t-[1px] border-blue-900 p-4 w-full lg:text-sm   text-[#333333]  font-medium ${
                    location.pathname === "/" ? "" : ""
                  }`}
                  to={"/"}
                >
                  Accueil
                </NavLink>
                <NavLink
                  className={`uppercase  font-medium hover:bg-blue-50 hover:font-bold text-sm p-4 w-full border-blue-900 border-[1px]   text-[#333333] ${
                    location.pathname.includes("/partenaires") ? "" : ""
                  }`}
                  to={"/partenaires"}
                >
                  Partenaires
                </NavLink>
              </div>
              <div className="flex items-center justify-between px-4 w-full gap-4 ">
                <NavLink
                  to="/salle"
                  className=" items-center w-1/3 flex py-1 justify-center text-sm font-medium gap-2 bg-main-blue border-2 border-[#1D428A] text-white rounded-lg" // Augmenté légèrement la largeur
                >
                  <img src="../img/mouse-pointer.png" className="w-[20px]" />
                  <span className="text-md">Recherche</span>
                </NavLink>

                <Link to="/partenaires/devenir-partenaire" className="w-2/3">
                  <Bouton
                    label="Prenez rendez-vous"
                    className="bg-main-red  text-white  font-semibold rounded-full py-2 text-sm " // Augmenté la largeur et diminué l'espacement
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
