import React from "react";
import { useClientsQuery } from "../../api/clients/hooks";
import Logos from "../partenaire_logos";

const ListeEntreprises = () => {
  const { data: clients = [], error, isLoading } = useClientsQuery();
  return <Logos datas={clients?.data} label={"Ils nous font confiance"} />;
};

export default ListeEntreprises;
