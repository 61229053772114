import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMousePointer, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom"; // Importez useNavigate
import Bouton from "./Bouton";

export default function Hero() {
  const [articleWithId1, setArticleWithId1] = useState(null);
  const [awards, setAwards] = useState(null);
  const navigate = useNavigate(); // Définissez useNavigate

  useEffect(() => {
    Promise.all([
      fetch("https://www.api.ketket.fewnu.app/api/articles", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // Prevent caching
        },
      })
        .then((response) => response.json())
        .then((data) => data.data || []), // Handle fallback for empty data

      fetch("https://www.api.ketket.fewnu.app/api/accolands", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // Prevent caching
        },
      })
        .then((response) => response.json())
        .then((data) => data.data || []), // Handle fallback for empty data
    ])
      .then(([articles, awardsResponse]) => {
        // Ensure we have data
        const newestArticle =
          articles.length > 0
            ? articles.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0]
            : null;
        setArticleWithId1(newestArticle);

        const newestAwards =
          awardsResponse.length > 0
            ? awardsResponse.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0]
            : null;
        setAwards(newestAwards);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Optionally set fallback states here if needed
        setArticleWithId1(null);
        setAwards(null);
      });
  }, []);

  // Construct the absolute URL for the images with fallbacks
  const baseUrl = "http://api.ketket.fewnu.app/storage/";
  const imageUrl = articleWithId1 ? `${baseUrl}${articleWithId1.image}` : "";
  const imageAward1 = awards ? `${baseUrl}${awards.image}` : "";
  const imageAward2 = awards ? `${baseUrl}${awards.image1}` : "";
  const imageAward3 = awards ? `${baseUrl}${awards.image2}` : "";

  // Fonction de gestion de clic pour le bouton "Devenir un partenaire"
  const handlePartnerClick = () => {
    navigate("/partenaire"); // Redirection vers la page partenaire
  };

  return (
    <div className="py-6">
      <div className=" w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5  flex justify-between mt-2 max-sm:mt-[0px] max-lg:flex-wrap">
        <div className="hero-text-container flex-col">
          <div className="hero-text bg-white">
            <div
              className="hero-title bg-hero-texture py-4 bg-no-repeat ;"
              style={{ backgroundSize: "400px" }}
            >
              <h1 className="font-quicksand font-bold text-[60px] max-sm:text-[40px]  max-sm:leading-[50px] text-main-blue max-w-sm ">
                {articleWithId1?.title
                  ? articleWithId1.title
                  : "WELLNESS AT WORK"}
              </h1>
            </div>
          </div>
          <div className="hero-subtitle py-2">
            {/* <div className="text-h5 leading-h6 font-arial max-md:text-h6 max-md:leading-h6">
                                {articleWithId1?.subtitle
                                ? articleWithId1.subtitle.split('. ').map((line, index) => (
                                    <p key={index} className="mb-4 text-justify">
                                        {line}
                                    </p>
                                ))
                                : (
                                    <>
                                        <p className="mb-14 text-justify" style={{position:'relative',bottom:'60px',left:'37px'}}>Rejoignez-nous et transformez votre forme physique dès aujourd'hui.</p>
                                        <p className="mb-14 text-justify" style={{position:'relative',bottom:'95px',left:'37px'}}>Des installations de pointe et des cours diversifiés vous attendent.</p>
                                    </>
                                )}
                            </div> */}
            <p className="text-h6 text-sm font-quicksand leading-[20px] font-semibold my-2 max-sm:my-0 ">
              Rejoignez-nous et transformez votre forme physique dès
              aujourd'hui.
            </p>
            <p className="text-h6 text-sm font-quicksand leading-[20px] font-semibold my-4  ">
              Des installations de pointe et des cours diversifiés vous
              attendent.
            </p>
          </div>

          <div className="flex my-10 gap-4 max-sm:mt-3">
            <Link to={"/salle"}>
              <Bouton
                icon={
                  <FontAwesomeIcon
                    icon={faMousePointer}
                    className="max-sm:hidden" 
                  />
                }
                label={"Rechercher une salle ou une activité"}
                className={
                  "bg-main-blue text-white text-sm p-2 max-md:text-sm max-sm:text-sm"
                }
              />
            </Link>
            <Link to={"/partenaires/devenir-partenaire"}>
              <Bouton
                icon={
                  <FontAwesomeIcon
                    icon={faUser}
                    className="max-sm:hidden" // Icône cachée pour les écrans "sm" et plus petits
                  />
                }
                label={"Devenons partenaires"}
                className={
                  "bg-white text-black text-[13px] border p-2 border-gray-300 max-md:text-sm max-sm:text-sm"
                }
              />
            </Link>
          </div>

          <div className="accolades flex gap-[1%] items-center justify-between xl:mt-16 2xl:mt-20 flex-nowrap max-md:flex-wrap max-lg:h-auto mt-4">
            {/* Premier award */}
            <div className="award flex items-center gap-[5%]">
              <img
                className="h-[40px] w-[40px]"
                src={imageAward1 ? imageAward1 : "../img/award1.png"}
                alt="award-icon-1"
              />
              <div className=" leading-2 text-xs xl:text-[13px] 2xl:text-[15px] font-arial font-bold">
                {awards?.description
                  ? awards.description
                  : "EXCEPTIONNEL COMMENTAIRES"}
              </div>
            </div>

            {/* Deuxième award */}
            <div className="award flex items-center gap-[5%] py-5 ">
              <img
                className="h-[40px] w-[40px]"
                src={imageAward2 ? imageAward2 : "../img/award2.png"}
                alt="award-icon-2"
              />
              <div className=" leading-2 text-xs xl:text-[13px] 2xl:text-[15px] font-arial font-bold">
                {awards?.description1
                  ? awards.description1
                  : "ENTRAÎNEURS DE CLASSE MONDIALE"}
              </div>
            </div>

            {/* Troisième award */}
            <div className="award flex items-center gap-[5%]">
              <img
                className="h-[40px] w-[40px]"
                src={imageAward3 ? imageAward3 : "../img/award3.png"}
                alt="award-icon-3"
              />
              <div className=" leading-2 text-xs xl:text-[13px] 2xl:text-[15px] font-arial font-bold">
                {awards?.description2
                  ? awards.description2
                  : "INSTALLATIONS EXTENSIVES"}
              </div>
            </div>
          </div>
        </div>

        {/**-------------l'image de droite---------------- */}
        <div className="hero-image-container max-lg:w-[100%] h-[340px] xl:h-[400px] 2xl:h-[500px] w-[40%] my-8">
          <img
            src={imageUrl ? imageUrl : "../img/hero-image.png"}
            alt="hero"
            className="w-[100%] relative h-[100%] object-cover rounded-lg shadow-lg"
          />
          <div className="h-[5%] -mt-[20%]">
            <img
              className="ml-[72.5%] w-[35%]"
              src="../img/red-circle.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
