import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5  py-6 lg:py-8">
      <div className="md:flex md:justify-between w-[80%] px-1 max-lg:w-[100%]">
        <div className="mb-6 md:mb-0">
          <Link to="/" className="flex items-center">
            <img
              className="w-[48px] h-[38px]"
              src="../img/logo.png"
              alt="Logo de ketket"
            />
            <img
              src="../img/letterKetKet.png"
              className="w-[120px] h-[23px]"
              alt="Ket ket"
            />
          </Link>
          <div>
            <p className="mt-4 flex items-center justify-center max-md:justify-normal md:justify-start text-sm font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-4 w-4 ml-6"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
              info@example.com
            </p>
            <p className="my-2 flex items-center justify-center max-md:justify-normal md:justify-start text-sm font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 ml-6 h-4 w-4"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
              + 01 234 567 88
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>
            <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">
              Site
            </h2>
            <ul className="text-gray-500 ">
              <li className="mb- text-sm">
                <a href="#" className="hover:underline">
                  Accueil
                </a>
              </li>
              <li className="mb- text-sm">
                <a href="#" className="hover:underline">
                  Qui Sommes-Nous
                </a>
              </li>
              <li className="mb- text-sm">
                <a href="#" className="hover:underline">
                  Nos Services
                </a>
              </li>
              <li className="mb- text-sm">
                <a href="#" className="hover:underline">
                  Partenaires
                </a>
              </li>
              <li className="mb- text-sm">
                <a href="#" className="hover:underline">
                  Search
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">
              OTHER
            </h2>
            <ul className="text-gray-500 ">
              <li className="mb-1 text-sm">
                <a href="#" className="hover:underline">
                  FAQ
                </a>
              </li>
              <li className="mb-1 text-sm">
                <a href="#" className="hover:underline">
                  Conditions d'utilisation
                </a>
              </li>
              <li className="mb-1 text-sm">
                <a href="#" className="hover:underline">
                  Politique de confidentialité
                </a>
              </li>
              <li className="mb-1 text-sm">
                <a href="#" className="hover:underline">
                  Options de paiement
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase">
              FOLLOW USE
            </h2>
            <div class="sm:flex sm:items-center sm:justify-between">
              <div class="flex mt-4 sm:justify-center sm:mt-0">
                <a
                  href="#"
                  className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 8 19"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="#"
                  class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 21 16"
                  >
                    <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                  </svg>
                </a>
                <a
                  href="#"
                  class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 17"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[0.5px] mt-4 bg-black sm:mx-auto w-full"></div>
    </footer>
  );
};
