import React, { useEffect, useState, useRef } from "react";
import { useModal } from "../../modal_context";
import { ModalView } from "../ModalView";
import { useNavigate } from "react-router";
import { apiImgUrl } from "../../../http/http";

function ComponentSearch({
  id,
  backgroundImage,
  Title,
  Time,
  pNumber,
  Location,
  isClicked,
  onDivClick,
  location,
  locations,
  fallbackLocations,
  fallbackImages,
  key,
}) {
  const [showViewModal, setShowViewModal] = useState(false); // Show Modal_view
  const navigate = useNavigate();

  const viewModalRef = useRef(null);
  const handleOpenViewModal = () => setShowViewModal(true);
  const handleCloseViewModal = () => setShowViewModal(false);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={onDivClick}
        className={`bg-white h-[150px] border-[#1D428A] border-2 rounded-[5px] shadow-shadow-search  flex transition-all ease-in-out duration-500 ${
          isClicked ? "bg-main-blue" : "hover:bg-[#F6F9FF]"
        }`}
      >
        <div className="h-[100%] w-[40%] flex items-center">
          <div
            className={`bg-cover bg-center h-[82%] w-[84%] mx-auto rounded-[5px]`}
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            {/* Content can go here if needed */}
          </div>{" "}
        </div>

        <div className="flex justify-center items-center w-[60%]">
          <div className="flex flex-col items-center justify-center  gap-[8px] ">
            <h6
              className={`text-sm font-quicksand w-[348px] text-center font-semibold  transition ease-in-out duration-300 ${
                isClicked ? "text-white" : "text-black"
              }`}
            >
              {Title}
            </h6>

            <div className="flex items-center justify-center  text-sm gap-5">
              <div className="flex items-center justify-center gap-[5px] ">
                <svg
                  width="14"
                  height="14"
                  className="ml-[2px] transition ease-in-out duration-300"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM14.2 14.2L9 11V5H10.5V10.2L15 12.9L14.2 14.2Z"
                    fill={isClicked ? "white" : "black"}
                  />
                </svg>
                <p
                  className={`font-arial font-bold text-[12px]   transition ease-in-out duration-300 
                ${isClicked ? "text-white" : "text-black"}`}
                >
                  {Time}
                </p>
              </div>

              <div className="flex font-medium items-center justify-center gap-[5px] ">
                <svg
                  className=" transition ease-in-out duration-300"
                  width="13"
                  height="13"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 12.46L12.73 11.85L10.21 14.37C7.37121 12.9262 5.0638 10.6188 3.62002 7.78L6.15002 5.25L5.54002 0H0.0300246C-0.549975 10.18 7.82002 18.55 18 17.97V12.46Z"
                    fill={isClicked ? "white" : "black"}
                  />
                </svg>

                <p
                  className={`font-arial text-[12px] transition ease-in-out duration-300 
                 ${isClicked ? "text-white" : "text-black"}`}
                >
                  {pNumber}
                </p>
              </div>
            </div>

            <div className="flex items-center  gap-2 transition ease-in-out duration-300">
              <svg
                // className="ml-[2px]"
                width="10"
                height="20"
                viewBox="0 0 14 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0Z"
                  fill={isClicked ? "white" : "black"}
                />
              </svg>

              <p
                className={`font-arial text-[12px] transition ease-in-out duration-300
                ${isClicked ? "text-white" : "text-black"}`}
              >
                {''}
                {Location}
              </p>
            </div>

            <div className="flex items-center justify-center gap-[10px] transition ease-in-out duration-300">
              <button
                className={` rounded-full text-[11px] p-1 px-4 text-center hover:opacity-80 text-white font-quicksand font-medium transition ease-in-out duration-300 ${
                  isClicked ? "bg-main-red" : "bg-main-blue"
                }`}
                onClick={() => navigate(`/salle/${id}`)}
              >
                Voir le Club
              </button>

              <button className=" rounded-full text-[12px] p-0.5 px-4 bg-white border-[#1D428A] border-[1.5px] z-auto text-center text-main-black font-quicksand font-bold">
                S’inscrire
              </button>
            </div>
          </div>
        </div>
      </div>

      {showViewModal && (
        <div className="fixed overflow-y-scroll inset-0 z-50 bg-[#1D428A] bg-opacity-25 flex justify-center">
          <div className="" ref={viewModalRef}>
            <ModalView
              onClose={handleCloseViewModal}
              name={location.nom}
              quartier={location.adresse.quartier}
              description={location.description}
              address={location.adresse}
              horaire={location.horaire}
              categorie={location.categorie.nom}
              activity={location.activites}
              handleCloseViewModal={handleCloseViewModal}
              image_collection={
                location.image
                  ? `${apiImgUrl}/storage/${location.image}`
                  : fallbackImages[fallbackImages.length - 1]
              }
              fallbackImages={fallbackImages}
              slidePicOne={
                location.image
                  ? `${apiImgUrl}/storage/${location.image}`
                  : fallbackImages[fallbackImages.length - 1]
              }
              location={location}
              locations={locations}
              slidePicTwo={
                location.image
                  ? `${apiImgUrl}/storage/${location.image}`
                  : fallbackImages[fallbackImages.length - 1]
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ComponentSearch;
