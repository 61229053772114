import React, { useState } from "react";
import NavBar from "./navbar";
import { faArrowRight, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "./Formulaires/Modal";
import Form from "./Formulaires/Form";
import { FormSport } from "./Formulaires/FormSport";
import { FormPartenaire } from "./Formulaires/FormPartenaire";
import { FormCollaborateur } from "./Formulaires/FormCollaborateur";
import { useAddContactMutation } from "../api/contactpartenaire/hooks";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DevenirPartenaire = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalThree, setShowModalThree] = useState(false);
  const [numero, setNumero] = useState("");
  const [numeroResponsable, setNumeroResponsable] = useState("");
  const dateCreation = new Date();
  const annee = dateCreation.getFullYear();
  const mois = String(dateCreation.getMonth() + 1).padStart(2, "0");
  const jour = String(dateCreation.getDate()).padStart(2, "0");
  const dateFormatte = `${annee}-${mois}-${jour}`;
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    prenom: "",
    description_etablissement: "",
    nom_etablissement: "",
    numero_responsable: "",
    nom: "",
    mail: "",
    position_hierarchique: "",
    metier: "",
    effectif: "",
    mail_responsable: "",
    poste: "",
    numero: "",
    date_creation: dateFormatte,
    date_modification: dateFormatte,
    type: "",
    adresse_id: 1,
    message: "",
    rendez_vous: "",
  });
  const { mutateAsync: addNewContact } = useAddContactMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    function determineType(formState) {
      if (formState.effectif) {
        return "Entreprise";
      } else if (formState.metier) {
        return "Partenaire";
      } else if (formState.numero_responsable) {
        return "Client";
      } else {
        return "Non renseigner";
      }
    }

    const payload = {
      nom: formState.nom,
      numero: numero,
      mail: formState.mail,
      effectif: formState.effectif || "",
      position_hierarchique:
        formState.position_hierarchique || "Non renseigner",
      poste: formState.poste || "Non renseigner",
      metier: formState.metier || "Non renseigner",
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      adresse_id: 1,
      type: determineType(formState),
      message: "Non renseigner",
      rendez_vous: formState.rendez_vous || "2024-11-20",
      prenom: formState.prenom || "Non renseigner",
      nom_etablissement: formState.nom_etablissement || "",
      mail_responsable:
        formState.mail_responsable || "nonrenseigner@example.com",
      numero_responsable: numeroResponsable || "Non renseigner",
      description_etablissement: formState.description_etablissement || "",
    };

    try {
      setIsLoading(true);
      const response = await addNewContact(payload);
      if (response.data) {
        setShowModal(false);
        setShowModalThree(false);
        setShowModalTwo(false);
        setFormState({
          prenom: "",
          description_etablissement: "",
          nom_etablissement: "",
          numero_responsable: "",
          nom: "",
          mail: "",
          position_hierarchique: "",
          metier: "",
          effectif: "",
          mail_responsable: "",
          poste: "",
          numero: "",
          date_creation: dateFormatte,
          date_modification: dateFormatte,
          type: "",
          adresse_id: 1,
          message: "",
          rendez_vous: "",
        });
        setNumero("");
        setNumeroResponsable("");
        toast.success("Contact ajouté avec succès !");
      } else {
        toast.error("Une erreur s'est produite.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur inattendue est survenue.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <NavBar />
      <div className="bg- w-full mx-auto  space-y-6">
        <div className="pt-6 md:pt-14  grid grid-cols-1 lg:grid-cols-2 ">
          <div className="col-span-1 p-4 sm:p-8 md:p-14 py-12">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-red-600">
              <span className="text-black">Devenons</span> Partenaire
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-[#254385] font-medium py-3 md:py-7">
              Prenez un rendez-vous, afin que nous puissions vous contacter et
              vous donner plus d’informations.
            </p>
            <ul className="list-disc  space-y-2 custom-marker font-medium text-sm leading-h5">
              <li className="   text-[14px] leading-[35px]">
                Nous sommes partenaires de plus de 5 salles de sport
              </li>
              <li className=" text-[14px] leading-[35px]">
                Nous offrons à nos clients l’accès à plusieurs activités, et
                nous <br /> prévoyons d’augmenter le nombre d’activités
              </li>
              <li className=" text-[14px] leading-[35px]">
                Nous offrons des solutions adaptées aux besoins des employés
              </li>
            </ul>
          </div>
          <div className="col-span-1 dots_n_activities  py-16 bg-dots-active bg-cover bg-center flex items-center  justify-center">
            <img
              className="absolute -z-10 left-[50%] hidden lg:block  w-[9%] top-[73%]"
              src="../img/blue-x.png"
              alt="blue-x"
            />
            <img
              src="../img/red-circle.png"
              className="w-[14%] -z-10 absolute md:hidden lg:block top-[90%] left-[53%]"
              alt="red-circle"
            />
            <div className="bg-[#1D428A]  text-white px-5 py-4 w-3/4 rounded-lg">
              <h2 className="text-2xl font-semibold text-white text-center pb-5 pt-2">
                Qui êtes-vous?
              </h2>
              <div className="space-y-4">
                <div
                  onClick={() => setShowModal(!showModal)}
                  className="flex cursor-pointer justify-between items-center w-full h-[100px]  md:h-[90px] px-4 py-3 bg-white border-2 hover:opacity-90 border-red-700 text-black rounded-md shadow  transition duration-200"
                >
                  <div className="w-full md:w-3/4 ">
                    <p className="font-bold text-[12px] md:text-sm">
                      Au Seins de mon entreprise, je suis responsable
                    </p>
                    <p className="text-[6px] md:text-[11px] ">
                      Ressources humaines, membre de la direction générale,{" "}
                      <br />
                      directeur de département, etc.
                    </p>
                  </div>
                  <button className="text-black">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>

                <div
                  onClick={() => setShowModalTwo(!showModalTwo)}
                  className="flex cursor-pointer justify-between items-center w-full h-[90px] px-4 py-0 md:py-3 bg-white border-2 border-red-700 hover:opacity-90 text-black rounded-md shadow  transition duration-200"
                >
                  <div className="w-full md:w-3/4  ">
                    <p className="font-bold text-[12px] md:text-sm">
                      Je suis un salarié d’une entreprise
                    </p>
                    <p className="text-[8px] md:text-[11px]">
                      Porteur(se) de projet pour la solution Ket Ket dans mon
                      entreprise
                    </p>
                  </div>

                  <button className="text-black">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
                <div
                  onClick={() => setShowModalThree(!showModalThree)}
                  className="flex cursor-pointer justify-between items-center w-full px-4 h-auto md:h-[90px] py-3 bg-white border-2 border-red-700 hover:opacity-90 text-black rounded-md shadow  transition duration-200"
                >
                  <div className="w-full md:w-3/4  ">
                    <p className="font-bold text-[11px] md:text-sm">
                      Je suis gérant d’une infrastructure sport et/ou bien-être
                    </p>
                    <p className="text-[11px] hidden md:block">
                      Propriétaire d’une salle de sports, je suis un enseignant
                      qui dirige des cours sportifs, etc
                    </p>
                  </div>
                  <button className="text-black">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
                {/* <div className="flex justify-between items-center w-full px-4 h-[90px] py-0 md:py-3 bg-white border-2 border-red-700 hover:opacity-90 text-black rounded-md shadow  transition duration-200">
                  <div className="w-full md:w-3/4  ">
                    <p className="font-bold text-[12px] md:text-sm">
                      Je suis un client qui n’est pas affilié avec une
                      entreprise
                    </p>
                  </div>

                  <button className="text-black">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        title="Financer la pratique sportive de vos collaborateurs"
      >
        <Form
          onSubmit={handleSubmit}
          FormContainer={
            <FormCollaborateur
              formState={formState}
              handleChange={handleChange}
              setPhone={setNumero}
              phone={numero}
            />
          }
          isLoading={isLoading}
        />
      </Modal>
      <Modal
        showModal={showModalThree}
        closeModal={() => setShowModalThree(false)}
        title="Devenez Notre Partenaire"
      >
        <Form
          onSubmit={handleSubmit}
          FormContainer={
            <FormPartenaire
              formState={formState}
              handleChange={handleChange}
              setPhone={setNumero}
              phone={numero}
              // handlePhoneChange={handlePhoneChange}
              setFormState={setFormState}
            />
          }
          isLoading={isLoading}
        />
      </Modal>
      <Modal
        showModal={showModalTwo}
        closeModal={() => setShowModalTwo(false)}
        title="Faites financer vos activités sportives"
      >
        <Form
          onSubmit={handleSubmit}
          FormContainer={
            <FormSport
              formState={formState}
              handleChange={handleChange}
              setPhone={setNumero}
              setPhoneRh={setNumeroResponsable}
              phone={numero}
              phoneRh={numeroResponsable}
            />
          }
          isLoading={isLoading}
        />
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default DevenirPartenaire;
