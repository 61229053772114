import { useQuery } from "@tanstack/react-query";
import { fetchClients } from "./api";

// Hook pour récupérer les Clients
export const useClientsQuery = () => {
  return useQuery({
    queryKey: ["clients"],
    queryFn: fetchClients,
  });
};
