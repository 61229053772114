import React, { useState, useEffect } from "react";

const CheckboxItem = ({
  label,
  id,
  smallFontSize,
  width,
  checkboxState,
  onCheckboxChange,
}) => {
  const [isChecked, setIsChecked] = useState(checkboxState[id] || false);
  const [borderAnimation, setBorderAnimation] = useState(false);

  // Synchronize local state with props
  useEffect(() => {
    setIsChecked(checkboxState[id]);
  }, [checkboxState, id]);

  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    setBorderAnimation(true);
    setTimeout(() => {
      setBorderAnimation(false);
    }, 300);

    onCheckboxChange(id, newCheckedState);
  };

  return (
    <div
      className={`flex items-center gap-[7px] col-span-1  rounded-[5px] mt-1  transition-all px-2 py-1 duration-400 ${
        isChecked ? "bg-white opacity-80" : "bg-[#1D428A] bg-opacity-5"
      } ${borderAnimation ? "border-black border-2" : " border-black"}`}
    >
      <input
        id={id}
        className={`rounded-[3px] cursor-pointer w-[16px] h-[16px] accent-black border-[1px] border-black appearance-none transition-all duration-400 ${
          isChecked ? "bg-black" : ""
        }`}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <label
        htmlFor={id}
        className={`text-[12px] font-quicksand text-black ${
          smallFontSize ? "text-[14.5px]" : ""
        } ${width ? "w-[0px]" : "w-auto]"} transition-all duration-400`}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckboxItem;
