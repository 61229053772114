import React from "react";
import Hero from "../components/hero";
import Apropos from "../components/partenaires/Apropos";
import Logos from "../components/partenaire_logos";
import Advantage from "../components/advantage";
import Service from "../components/service";
import Activities from "../components/activity";
import { Footer } from "../components/Footer";
import ListeEntreprises from "../components/partenaires/ListeEntreprises";

const Homepage = () => {
  const LOGOS = [
    { src: "/img/parter/senelec.png" },
    { src: "/img/parter/baobab.png" },
    { src: "/img/parter/elton.png" },
    { src: "/img/parter/wave.png" },
    { src: "/img/parter/prem.png" },
    { src: "/img/parter/senelec.png" },
    { src: "/img/parter/baobab.png" },
    { src: "/img/parter/elton.png" },
  ];
  return (
    <section className=" max-md:pt-0">
      <Hero />
      <Apropos />
      <div className="py-10 max-md:py-0">
        <ListeEntreprises label={"Les clients qui nous font confiance"} />
      </div>
      <div className="">
        <Advantage />
      </div>
      <div className="">
        <Service />
      </div>
      <div className="mb-5">
        <Activities />
      </div>

      <div className="">
        <Footer />
      </div>
    </section>
  );
};

export default Homepage;
