import React, { useState } from "react";
import CheckboxItem from "./small_components/checkbox_component";

export function ModalSearch({
  label,
  id,
  smallFontSize,
  width,
  checkboxState,
  onCheckboxChange,
  onClose,
  checkboxState_Activity,
  onCheckboxChange_Activity,
}) {
  const datas = [
    { id: "football", label: "Football" },
    { id: "natation", label: "Natation" },
    { id: "badminton", label: "Badminton" },
    { id: "yoga", label: "Yoga" },
    { id: "karate", label: "Karaté" },
    { id: "boxe", label: "Boxe" },
    { id: "mma", label: "MMA" },
    { id: "gym", label: "Gym" },
    { id: "thai", label: "Muay Thai" },
    { id: "musculation", label: "Musculation" },
    { id: "jjb", label: "Jiujitsu 🇧🇷" },
    { id: "fitness", label: "Fitness" },
  ];
  return (
    <>
      <div className="bg-white min-w-[90%] w-[450px] items-center justify-center mx-auto py-4  rounded-[5px] overflow-hidden ">
        <div className="flex flex-col px-4">
          <h5 className="text-[12px] mb-4 leading-p text-black font-quicksand font-bold  mx-auto">
            Filtrer par options
          </h5>
          {/* <h5 className="text-[12px] mb-4 leading-p text-black font-quicksand font-bold  mx-auto">
            Filtrer par horaires et options
          </h5> */}

          {/* <h6 className="text-[12px] leading-p text-black font-quicksand  font-bold">
            Horaires
          </h6> */}

          <form
            action=""
            className="bg-black-400 m-auto    flex flex-col place-content-between"
          >
            {/* <div className="flex mb-3 gap-2 items-center">
              <div className=" py-2 bg-[#F6F9FF] px-2 flex gap-[6px] rounded-[5px] ">
                <input
                  className=" rounded-[3px] w-[16px] h-[16px] accent-black border-[1px] checked:bg-black cursor-pointer border-black appearance-none"
                  type="checkbox"
                />
                <div className="text-[12px] pr-3 font-quicksand text-black">
                  24h/7h
                </div>
              </div>
              <div className="py-2 bg-[#F6F9FF] flex gap-[6px] rounded-[5px] px-2">
                <input
                  className=" rounded-[3px] w-[16px] h-[16px] accent-black border-[1px] checked:bg-black cursor-pointer border-black appearance-none"
                  type="checkbox"
                />
                <div className="text-[12px] pr-3 font-quicksand  text-black">
                  24h/24h
                </div>
              </div>
            </div> */}

            <div className=" flex flex-col ">
              <div className=" border-[1px] border-[#828282] border-dashed"></div>

              <div className="text-[12px] leading-p text-black font-quicksand pt-1.5 font-bold">
                Options de fitness et bien-être
              </div>

              <div className="  flex mt-1">
                <div className="py-1  bg-[#F6F9FF] flex items-center gap-[9px] rounded-[5px] mt-1 px-2">
                  <input
                    className="rounded-[3px] w-[16px] h-[16px] accent-black border-[1px] checked:bg-black cursor-pointer border-black appearance-none"
                    type="checkbox"
                    id="salle_de_sport"
                    name="Salle de sport"
                    checked={checkboxState.salle_de_sport}
                    onChange={() => onCheckboxChange("salle_de_sport")}
                  />
                  <div className="text-[12px] font-quicksand text-black">
                    Salle de sport
                  </div>
                </div>
                <div className=" py-2 bg-[#F6F9FF] flex items-center gap-[7px] rounded-[5px] mt-1 mx-1 px-2">
                  <input
                    className=" rounded-[3px] w-[16px] h-[16px] accent-black border-[1px] checked:bg-black cursor-pointer border-black appearance-none"
                    type="checkbox"
                    id="club_de_sport"
                    name="Club"
                    checked={checkboxState.club_de_sport}
                    onChange={() => onCheckboxChange("club_de_sport")}
                  />
                  <div className="text-[12px]  font-quicksand text-black">
                    Club
                  </div>
                </div>
                <div className=" py-2 bg-[#F6F9FF] flex items-center gap-[7px] rounded-[5px] mt-1 px-2">
                  <input
                    className=" rounded-[3px] w-[16px] h-[16px] accent-black border-[1px] checked:bg-black cursor-pointer border-black appearance-none"
                    type="checkbox"
                  />
                  <div className="text-[12px] pr-3 font-quicksand text-black">
                    Cours
                  </div>
                </div>
              </div>

              <h6 className="text-[12px] leading-p text-black font-quicksand   font-bold">
                Nos options
              </h6>

              {/* <div className=" grid grid-cols-4 items-center flex-wrap mt-1  gap-1">
                <CheckboxItem
                  id="football"
                  label="Football"
                  name="Football"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="natation"
                  label="Natation"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="badminton"
                  label="Badminton"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="yoga"
                  label="Yoga"
                  width
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="karate"
                  label="Karaté"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="boxe"
                  label="Boxe"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="mma"
                  label="MMA"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="Gym"
                  label="Gym"
                  width
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="thai"
                  label="Muay Thai"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="musculation"
                  label="Musculation"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="jjb"
                  label="Jiujitsu 🇧🇷"
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
                <CheckboxItem
                  id="fitness"
                  label="Fitness"
                  width
                  checkboxState={checkboxState}
                  onCheckboxChange={onCheckboxChange}
                />
              </div> */}
              <div className="grid grid-cols-4 items-center flex-wrap mt-1 gap-1">
                {datas.map((data) => (
                  <CheckboxItem
                    key={data.id}
                    id={data.id}
                    label={data.label}
                    checkboxState={checkboxState}
                    onCheckboxChange={onCheckboxChange}
                  />
                ))}
              </div>
            </div>
            <div className=" flex justify-center pt-4">
              <button className="py-1 px-2 hover:opacity-80 bg-main-blue rounded-[10px] text-center text-white font-quicksand text-[12px]">
                Réinitialiser les filtres
              </button>

              
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
