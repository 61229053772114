import React, { useEffect, useState, useRef } from "react";
import Search_main from "../components/components_search_page/search_main";
import Map from "../components/components_search_page/map";
import {
  Modal_view,
  ModalView,
} from "../components/components_search_page/ModalView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModalSearch } from "../components/components_search_page/modalSearch";
import { Link } from "react-router-dom";
import useRecherche from "../utils/hooks/recherche/useRecherche";
import Logo from "../components/recherche/logo";

export default function SearchPage(handleCloseViewModal) {
  const {
    showSearchModal,
    checkboxState,
    checkboxState_Activity,
    searchModalRef,
    viewModalRef,
    selectedEnterprise,
    setSelectedEnterprise,
    showViewModal,
    onCheckboxChange_Activity,
    onCheckboxChange,
    toggleCheckboxState,
    handleOpenSearchModal,
    handleCloseSearchModal,
  } = useRecherche();

  useEffect(() => {
    if (showSearchModal || showViewModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [showSearchModal, showViewModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchModalRef.current &&
        !searchModalRef.current.contains(event.target)
      ) {
        handleCloseSearchModal();
      }
      if (
        viewModalRef.current &&
        !viewModalRef.current.contains(event.target)
      ) {
        handleCloseViewModal();
      }
    };

    if (showSearchModal || showViewModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSearchModal, showViewModal]);
  const queryClient = new QueryClient();

  return (
    <div className="overflow-hidden relative">
      {showSearchModal && (
        <div className="fixed inset-0 z-50 bg-[#1D428A] bg-opacity-25 flex justify-center items-center">
          <div className="" ref={searchModalRef}>
            <ModalSearch
              checkboxState={checkboxState}
              onCheckboxChange={onCheckboxChange}
              onClose={handleCloseSearchModal}
              onCheckboxChange_Activity={onCheckboxChange_Activity}
              checkboxState_Activity={checkboxState_Activity}
            />
          </div>
        </div>
      )}

      <div className="testing grid grid-cols-1 md:grid-cols-8 w-full h-full  z-10 overflow-hidden">
        <div className="md:col-span-4 lg:col-span-3  h-full px-">
          <Logo />
          <Search_main
            setSelectedEnterprise={setSelectedEnterprise}
            onOpenSearchModal={handleOpenSearchModal}
            checkboxState={checkboxState}
            toggleCheckboxState={toggleCheckboxState}
            checkboxState_Activity={checkboxState_Activity}
          />
        </div>
        <div className="md:col-span-4 lg:col-span-5 h-full z-10">
          <QueryClientProvider client={queryClient}>
            <Map
              latitude={selectedEnterprise?.adresse?.latitude}
              longitude={selectedEnterprise?.adresse?.longitude}
              entrepriseName={selectedEnterprise?.nom}
            />
          </QueryClientProvider>
        </div>
      </div>
    </div>
  );
}
