import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useLocationsQuery } from "../api/partenaireSport/hooks";
import { useParams } from "react-router";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Voir_tag from "../components/components_search_page/small_components/modal_voir_compo";
import { Link } from "react-router-dom";
import { Modal } from "../components/details-salle-sport/Modal";
import { apiImgUrl } from "../http/http";

export const DetailsClub = () => {
  const { slug } = useParams();
  const { data: infosClub = [], error, isLoading } = useLocationsQuery(slug);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hover, setHover] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isLoading && infosClub?.data?.length > 0) {
      const imageUrls = infosClub.data.flatMap(
        (item) =>
          item.services?.flatMap(
            (service) => `${apiImgUrl}/storage/${service.image}`
          ) || []
      );

      setImages(imageUrls);
    } else {
      setImages([]);
    }
  }, [isLoading, infosClub]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <div className="bg- mx-auto w-[73%]  max-sm:w-full max-md:px-10 py-9">
        <div className="flex justify-end gap-4 ">
          <Link
            to={"/salle"}
            className={
              "bg-main-blue text-white  text-sm   rounded-full flex items-center px-2.5 py-2 gap-2 "
            }
          >
            <FontAwesomeIcon icon={faXmark} s />
          </Link>
        </div>
        {infosClub?.data?.map((info, index) => (
          <>
            <h4 className="font-quicksand space-x-3 font-bold text-4xl max-sm:text-sm">
              {info.nom} -{" "}
              <span className="font-medium font-quicksand capitalize">
                {info.adresse.ville}, {info.adresse.quartier}
              </span>
            </h4>
            <div className="tags w-full h-[60px]  mt-auto flex items-center gap-[15px]">
              <div className="px-3 py-1.5 rounded-[5px] bg-main-red flex items-center justify-center ">
                <p className="text-center leading-p text-sm font-bold align-middle  text-white font-quicksand capitalize">
                  {info.categorie.nom}
                </p>
              </div>

              <div className="bg-main-blue px-3 py-1.5 rounded-[5px] flex items-center justify-center ">
                <p className="text-center text-sm leading-p font-bold text-white align-middle font-quicksand capitalize">
                  {info.numero}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between">
              <div className="w-[63%] rounded-sm max-md:w-full max-md:mb-5">
                <div
                  className="w-[100%] z-10 h-[300px] cursor-pointer flex rounded-[5px]"
                  onMouseEnter={() => setHover(false)}
                  onMouseLeave={() => setHover(true)}
                  onClick={() =>
                    handleImageClick(
                      `${apiImgUrl}/storage/${info.services[0].image}`
                    )
                  }
                  style={{
                    backgroundSize: "cover",
                    backgroundImage: `url(${apiImgUrl}/storage/${info.services[0].image})`,
                    backgroundPosition: "center",
                  }}
                >
                  {hover ? null : (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className=" animate-slideUp  flex h-[130px] z-10 w-[100%] inset-0 backdrop-blur-2xl bg-opacity-10 rounded-b-[5px] mt-auto bg-black pl-5 gap-5 items-center border ease-in-out delay-300 border-[#D4D4D4] border-opacity-30  "
                    >
                      {info.services.map((service, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleImageClick(
                              `${apiImgUrl}/storage/${service.image}`
                            )
                          }
                          className="bg-slate-300 w-[120px] h-[80px]"
                          style={{
                            backgroundImage: `url(${apiImgUrl}/storage/${service.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="text-h6 text-sm leading-p h-11 w-full mt-[3%] font-quicksand font-semibold  z-20">
                  {info.description}
                </div>
                <div className="ml-auto w-full max-md:mb-10 max-md:mt-6 max-md:w-[100%] h-[145px] bg-white">
                  <h5 className="text-h5 leading-h5 font-arial mt-[2%]  font-bold text-black ">
                    Sports et activités proposés
                  </h5>
                  <div className="w-[100%] h-[100%] overflow-x-auto overflow-y-hidden scrollbar-hide mt-[21px] flex gap-[12px]">
                    {info.services?.map((service, index) => (
                      <Voir_tag key={index} title={service.activite.nom} />
                    ))}
                  </div>
                </div>
              </div>
              <div className=" w-[34%] max-md:w-full  ">
                <h1 className="text-h6 mb-0 pb-0 ml-[5.7%] leading-[24px] font-bold font-quicksand -mt-[7.25%] text-black ">
                  Adresse
                </h1>
                <div className="bg-main-blue  mb-2 px-4 py-4 rounded-[15px]">
                  <h6 className="text-white mb-3 font-quicksand font-semibold text-sm">
                    {info.adresse.rue + " " + info.adresse.ville}
                  </h6>
                  <div className="  mt-2 mx-auto rounded-lg">
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0, borderRadius: 15 }}
                      src={`https://www.google.com/maps?q=${info.adresse.latitude},${info.adresse.longitude}&hl=es;z=15&output=embed`}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <h6 className="text-h6  ml-[5.7%] font-bold font-quicksand mt-[2.5%] text-black ">
                  Horaires
                </h6>
                <div className=" h-[50px] w-2/3 bg-main-blue mt-[1%] rounded-[10px] flex items-center">
                  <svg
                    className="ml-[5%]"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 2.6665C14.249 2.6665 12.5152 3.01138 10.8975 3.68144C9.27984 4.35151 7.80998 5.33363 6.57187 6.57175C4.07138 9.07223 2.66663 12.4636 2.66663 15.9998C2.66663 19.5361 4.07138 22.9274 6.57187 25.4279C7.80998 26.666 9.27984 27.6482 10.8975 28.3182C12.5152 28.9883 14.249 29.3332 16 29.3332C19.5362 29.3332 22.9276 27.9284 25.428 25.4279C27.9285 22.9274 29.3333 19.5361 29.3333 15.9998C29.3333 14.2489 28.9884 12.5151 28.3184 10.8974C27.6483 9.27972 26.6662 7.80986 25.428 6.57175C24.1899 5.33363 22.7201 4.35151 21.1024 3.68144C19.4847 3.01138 17.7509 2.6665 16 2.6665ZM21.6 21.5998L14.6666 17.3332V9.33317H16.6666V16.2665L22.6666 19.8665L21.6 21.5998Z"
                      fill="white"
                    />
                  </svg>

                  <p className="text-md leading-h5 font-arial ml-[0.5%] font-semibold text-white">
                    {info.horaire}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <Modal
        showModal={open}
        closeModal={closeModal}
        images={images ? images : []}
        selectedImage={selectedImage}
      ></Modal>
    </>
  );
};
