import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavbarLink = () => {
  const location = useLocation();
  return (
    <>
      {links.map((menu, index) => (
        <NavLink
          key={index}
          className={` uppercase Navlinks text-sm  text-[#333333]  font-bold ${
            location.pathname === menu.link ? "AfterLink" : ""
          }`}
          to={menu.link}
        >
          {menu.name}
        </NavLink>
      ))}
    </>
  );
};

export default NavbarLink;

const links = [
  {
    name: "Accueil",
    link: "/",
  },
  {
    name: "Partenaires",
    link: "/partenaires",
  },
];
