import React from "react";
import { apiImgUrl } from "../http/http";

const Logos = ({ label, datas }) => {
  return (
    <div className="flex flex-col gap-[10%] w-[90%] pe-4 max-sm:w-full max-sm:px-5  mx-auto bg-  pt-5 text-">
      <h4 className="text-h4 text-center leading-[39px]  font-bold font-quicksand   ">
        {label}
      </h4>
      <div className="flex justify-between items-center flex-wrap pt-8 mb-3 pb-12 lg:pb-0">
        {datas && datas.length > 0 ? (
          datas.map((logo, index) => (
            <div key={index} className="w-[130px] mb-5">
              <img
                className="object-contain"
                src={logo.logo ? `${apiImgUrl}/storage/${logo.logo}` : logo.src}
                alt={`Logo ${index + 1}`}
              />
            </div>
          ))
        ) : (
          <CardLoading />
        )}
      </div>
    </div>
  );
};

export default Logos;

export const CardLoading = () => {
  return (
    <>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
      <div className="h-[100px] mb-3 w-[130px] rounded-lg bg-slate-100 animate-pulse"></div>
    </>
  );
};
