import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SearchPage from "./pages/search";
import Partenaire from "./components/partenaire";
import DevenirPartenaire from "./components/devenirPartenaire";
import "react-phone-input-2/lib/style.css";
import { ModalProvider } from "./components/modal_context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DetailsClub } from "./pages/DetailsClub";
import RechercheContextProvider from "./utils/contexte/recherche/RechercheContext";
import Partenaires from "./pages/Partenaires";
import NotFound from "./pages/NotFound";
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "salle",
    element: <SearchPage />,
  },
  {
    path: "salle/:slug",
    element: <DetailsClub />,
  },
  {
    path: "partenaires",
    element: <Partenaires />,
  },
  {
    path: "partenaires/devenir-partenaire",
    element: <DevenirPartenaire />,
  },
  {
    path: "*",
    element: <NotFound/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RechercheContextProvider>
        <ModalProvider>
          <RouterProvider router={router} />
        </ModalProvider>
      </RechercheContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
