import React from "react";
import PhoneInput from "react-phone-input-2";

const InputPhone = ({ phone, setPhone, label }) => {
  return (
    <div className="flex flex-col w-full mb-2 md:mb-1">
      <label
        className="form-label mb-2 text-[12px] md:text-sm "
        htmlFor="phone"
      >
        {label}
      </label>
      <PhoneInput
        className="rounded-sm border-0  outline-0  w-full ring-1 ring-[#EEF4FF]   placeholder:text-[C8C8C8]  sm:text-sm md:text-[12px] sm:leading-6"
        // country={"sn"}
        value={phone}
        onChange={(phone) => setPhone(phone)}
        onlyCountries={["sn", "fr", "us", "gb"]}
        enableAreaCodes={true}
        disableDropdown={false}
        disableCountryCode={false}
        placeholder="77XXXXXXX"
        buttonClass="hide-flag"
        inputStyle={{
          width: "100%",
          backgroundColor: "#F6F9FF",
          border: 0,
        }}
        dropdownStyle={{
          backgroundColor: "#F6F9FF",
          border: 0,
        }}
      />
    </div>
  );
};

export default InputPhone;
