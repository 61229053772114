import React from "react";
import TexteComponent from "./TexteComponent";

const ImageStyle = () => {
  return (
    <>
      <img
        className="absolute left-[10.5%] w-[9.5%] -top-[3%] max-sm:-top-[3%]"
        src="../img/blue-x.png"
        alt="image-blue"
      />
      <img
        className="absolute left-[83.9%] w-[9.5%] top-[29.3%] -rotate-[15deg]"
        src="../img/blue-x.png"
        alt="image-blue"
      />
      <img
        className="absolute left-[86.5%] w-[7%] top-[65%] -rotate-[0deg]"
        src="../img/red-x.png"
        alt="image-red"
      />
      <img
        className="absolute left-[20%] w-[7%] top-[65%] rotate-[13deg]"
        src="../img/red-x.png"
        alt="image-red"
      />
    </>
  );
};

const PourquoiKetKet = ({ about, aboutImage1 }) => {
  return (
    <div className="grid md:grid-cols-2 gap-5 w-[100%] ">
      <div className="col-span-1">
        <TexteComponent
          title={about?.section1_title}
          content={about?.section1_content}
          titleDefault={"Ket Ket, C’est Quoi?"}
          contentDefault="Ket Ket est une entreprise sénégalaise dédiée à l'amélioration du bien-être des salariés en simplifiant l'accès aux structures sportives amateurs, en proposant des solutions d'adhésion aux entreprises pour soutenir et financer la pratique sportive de leurs employés."
        />
      </div>

      <div className="flex lg:justify-end">
        <img
          width={500}
          height={300}
          src={aboutImage1 ? aboutImage1 : "../img/macbook.png"}
          alt="Description of the "
        />
      </div>
    </div>
  );
};

const AccesSports = ({ about, aboutImage2, aboutImage3 }) => {
  return (
    <div className="about-blocks position flex max-lg:flex-col-reverse">
      <div className="">
        <div
          className="macbook  max-lg:hidden relative mt-[5%] w-[88.95%] max-md:w-[100%]  rounded-[20px] z-30 "
          style={{
            backgroundImage: aboutImage2
              ? `url(${aboutImage2})`
              : "url(../img/about2.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "360px",
            height: "170px",
          }}
        ></div>

        <div
          className="relative bg-cover max-md:w-[100%] max-md:mt-[40px] max-lg:mt-10  bg-center macbook vertical-image w-[180px] h-[250px] -mt-[12%] ml-[4.9%] z-30 rounded-[20px] max-lg:w-[95%] max-lg:min-h-[200px] max-lg:mx-auto  "
          style={{
            backgroundImage: aboutImage3
              ? `url(${aboutImage3})`
              : "url(../img/about3.png)",
          }}
        ></div>

        <div
          className="macbook max-lg:hidden vertical-image w-[210px] h-[120px] -mt-[39%] ml-[48%] z-6 rounded-[20px] 
       bg-cover bg-center relative right-[-40px] top-[-30px] max-[1700px]:-mt-[35%] 
       max-[1750px]:w-[53%] max-[1600px]:-mt-[38%] max-ml:-mt-[38%] max-xl:-mt-[43%] 
       max-lg:w-[95%] max-lg:min-h-[200px] max-lg:mx-auto max-lg:-mt-0 "
          style={{
            backgroundImage: aboutImage3
              ? `url(${aboutImage3})`
              : "url(../img/pairgloves.jpg)",
          }}
        >
          {/* <img
            src="../img/red-circle.png"
            className="absolute  w-[150%] -mt-[100%]  ml-[13%]  transform -translate-x-1/2 -translate-y-1/2 z-0 max-lg:hidden"
            alt="image-circle"
          /> */}
        </div>
      </div>

      <div className="about-des1 w-[100%] mt-[1.55%] max-sm:mt-0 ml-[16.4%] max-sm:w-[100%] max-sm:mx-0 max-sm:ml-[0%] flex-col max-ml:w-[48.5%] max-ml:ml-auto max-lg:w-[95%] ">
        <TexteComponent
          title={about?.section2_title}
          content={about?.section2_content}
          titleDefault={"Accès au sport & promotion d'un mode de vie actif"}
          contentDefault="Ket Ket facilite l'accès des salariés à des installations sportives de qualité, encourage une pratique régulière du sport et soutient le financement des activités sportives par les entreprises pour promouvoir un mode de vie actif, réduire le stress et renforcer les liens sociaux."
        />
      </div>
    </div>
  );
};

const BienEtreDeveloppement = ({ about, aboutImage5 }) => {
  return (
    <div className="about-blocks mt-[0%] flex h-[45%] w-[100%] max-lg:flex-col items-center max-lg:h-[100%] max-lg:mt-[5%] max-md:mt-[0px] max-md:mx-0">
      <div className="about-des1 w-[100%] ml-[4.75%] max-md:ml-0 h-[100%] flex-col max-ml:w-[48.5%] max-ml:ml-[0] max-lg:w-[95%] max-md:w-[100%] max-lg:h-[40%] max-lg:ml-auto ">
        <TexteComponent
          title={about?.description}
          content={about?.extra_info}
          titleDefault={"Bien-être global et développement personnel"}
          contentDefault="Chez Ket Ket, notre engagement est de promouvoir le bien-être des salariés par des programmes sportifs qui améliorent la santé physique, renforcent la confiance en soi et soutiennent le développement personnel pour une atmosphère de travail équilibrée et productive."
        />
      </div>

      <div className="relative h-[45%] w-[48%] ml-[5%] mt-[1.5%] max-ml:w-[48.5%] max-ml:ml-auto max-ml:mt-[6%] max-xl:mt-[10%] flex flex-col justify-end max-lg:w-[100%] max-lg:m-auto max-lg:mt-[5%]">
        <img
          className="macbook relative w-[80.5%] max-md:w-[100%] h-[90%] rounded-[20px] max-lg:m-auto ml-[20%]"
          src={aboutImage5 ? aboutImage5 : "../img/about5.png"}
          alt="Description of the image"
        />
      </div>
    </div>
  );
};

export { ImageStyle, PourquoiKetKet, AccesSports, BienEtreDeveloppement };