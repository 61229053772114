import { ApiBaseUrl } from "../http/http";




// Fonction générique pour gérer les requêtes fetch
export const fetchApi = async (endpoint, method = "GET", body = null) => {
  const response = await fetch(`${ApiBaseUrl}${endpoint}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
    body: body ? JSON.stringify(body) : null,
  });

  if (response.redirected) {
    // Ici, vous pouvez décider de suivre la redirection ou non
    // Par exemple, vous pourriez renvoyer l'URL de redirection
    return { redirected: true, url: response.url };
  }
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
};
